.welcome-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.mouse {
  display: none;
}

.welcome-container .left,
.welcome-container .right {
  flex: 1;
}

.welcome-container .left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-container .right {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-items: flex-end;
  height: 100vh;
}

.welcome-container .left svg {
  flex: 1;
  height: 45%;
}

.welcome-container .right svg {
  flex: 1;
  height: 100%;
}

.welcome-container .mouse svg {
  width: 100px;
}

@media (min-width: 1500px) {
  .welcome-container {
    flex-direction: row;
  }
  .welcome-container .mouse {
    display: block;
    position: absolute;
    bottom: 50px;
    left: 50%;
    margin-left: -100px;
  }
}
