.welcome-container {
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.mouse {
  display: none;
}

.welcome-container .left, .welcome-container .right {
  flex: 1;
}

.welcome-container .left {
  justify-content: center;
  align-items: center;
  display: flex;
}

.welcome-container .right {
  width: 100%;
  height: 100vh;
  place-items: flex-end;
  display: flex;
}

.welcome-container .left svg {
  height: 45%;
  flex: 1;
}

.welcome-container .right svg {
  height: 100%;
  flex: 1;
}

.welcome-container .mouse svg {
  width: 100px;
}

@media (min-width: 1500px) {
  .welcome-container {
    flex-direction: row;
  }

  .welcome-container .mouse {
    margin-left: -100px;
    display: block;
    position: absolute;
    bottom: 50px;
    left: 50%;
  }
}

/*# sourceMappingURL=index.e1732189.css.map */
